jQuery(document).ready(function ($) {

  // hash focus onload
  if (document.location.hash) {
    var myAnchor = document.location.hash;
    $(myAnchor).attr('tabindex', -1).on('blur focusout', function () {
      $(this).removeAttr('tabindex');
    }).focus();
  }

  // hash focus inline
  $(window).bind('hashchange', function () {
    var hash = "#" + window.location.hash.replace(/^#/, '');
    if (hash != "#") {
      $(hash).attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
    else {
      $("#headcontainer").attr('tabindex', -1).on('blur focusout', function () {
        $(this).removeAttr('tabindex');
      }).focus();
    }
  });

  // mobile nav
  $('.nav-btn-open').click(function () {
    $('.nav-primary').show();
  });
  $('.nav-btn-close').click(function () {
    $('.nav-primary').hide();
  });
  $(function () {
    $('.nav-btn-open').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $('.nav-primary').show();
      }
    });
  });
  $(function () {
    $('.nav-btn-close').keypress(function (e) {
      if (e.keyCode == 13 || e.keyCode == 32) {
        e.preventDefault();
        $('.nav-primary').hide();
      }
    });
  });

  // add active class to current page based on body class
  // var matchingClass = $('body').attr('class');
  // console.log(matchingClass);
  // $('.nav-primary .ul1 .li1').filter('.' + matchingClass.split(/\s+/).join('.')).addClass('active');
  $('.nav-primary .ul1 .li1').each(function () {
    var classes = this.classList;
    for (var i = 0, len = classes.length; i < len; i++) {
      if ($('body').hasClass(classes[i])) {
        $(this).addClass('active');
      }
    }
  });

  $('.video .title a, .video .play').on("click", function () {
    $(this).parents().next('.iframe').addClass('reveal');
    $('.iframe.reveal').append('<span class="close"><span class="fa fa-close"></span></span>');
    $('.iframe.reveal').append('<span class="background-overlay"></span>');
    $('.iframe.reveal iframe[data-src]').each(function () {
      $(this).attr('src', $(this).attr('data-src'));
    });
    $('.close, .background-overlay').on("click", function () {
      $('.iframe.reveal iframe').removeAttr('src');
      $('.iframe').removeClass('reveal');
      $(this).remove();
    });
    $(document).on('keydown', function (e) {
      if (e.keyCode === 27) { // ESC
        $('.iframe.reveal iframe').removeAttr('src');
        $('.iframe').removeClass('reveal');
        $('.close, .background-overlay').remove();
      }
    });
  });

  //Video Categories on mobile
  $('.video-categories span').on("click", function () {
    $('.video-categories > ul').slideToggle();
  });

  $('.video-categories li a:not(#all)').on('click', function () {
    $('li a#all').removeClass("active");
  });

  $('.video-categories li a').on('click', function () {
    var category = $(this).attr('id');
    $(this).addClass("active");

    if (category == 'all') {
      $('.recent-videos .all').show();
    } else {
      $('.recent-videos .video:not(.' + category + ')').hide();
      $('.recent-videos .' + category).show();
      $('.video-categories li a:not(#' + category + ')').on('click', function () {
        $('li a#' + category).removeClass("active");
      });
    }
  });

  //Sticky CTA clear Footer
  $(document).scroll(function () {
    if ($('.sticky-cta').offset().top + $('.sticky-cta').height() >= $('footer').offset().top - 10)
      $('.sticky-cta').css('position', 'relative');
    if ($(document).scrollTop() + window.innerHeight < $('footer').offset().top)
      $('.sticky-cta').css('position', 'fixed');
  });

  //Hide empty CD cells
  $(window).on('load', function () {
    $('.emptyCell, .clear, .alignBottom.minSize1').css({ 'display': 'none' });
    $('.buttonContainer').append('<span class="fa fa-arrow-right" aria-hidden="true"></span>');
  });

  $('.content-inner').responsivevideos();

  //Smooth Scroll START
  $('a[href*="#"]')
    // Remove links that don't actually link to anything
    .not('[href="#"]')
    .not('[href="#0"]')
    .click(function (event) {
      // On-page links
      if (
        location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname
      ) {
        // Figure out element to scroll to
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
        // Does a scroll target exist?
        if (target.length) {
          // Only prevent default if animation is actually gonna happen
          event.preventDefault();
          $('html, body').animate({
            scrollTop: target.offset().top
          }, 1000, function () {
            // Callback after animation
            // Must change focus!
            var $target = $(target);
            $target.focus();
            if ($target.is(":focus")) { // Checking if the target was focused
              return false;
            } else {
              $target.attr('tabindex', '-1'); // Adding tabindex for elements not focusable
              $target.focus(); // Set focus again
            }
          });
        }
      }
    });
  //Smooth Scroll END

});





