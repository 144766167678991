jQuery(document).ready(function ($) {
  $('.accordions').each(function () {
    var $this = $(this);
    $this.addClass('active');
  });
  $('.accordions .accordion-title').each(function () {
    var $this = $(this);
    // create unique id for a11y relationship
    var id = 'accordion-' + $this.index();
    // wrap the content and make it focusable
    if ($this.hasClass('open')) {
      $this.addClass('is-active').nextUntil('.accordion-title').wrapAll('<div id="' + id + '" aria-hidden="false">');
      // $this.nextUntil('.accordion-title').wrapAll('<div id="'+ id +'" aria-hidden="true">');
      var panel = $this.next();
      // Add the button inside the <h2> so both the heading and button semanics are read
      $this.wrapInner('<button aria-expanded="true" aria-controls="' + id + '">');
      var button = $this.children('button');
      // Toggle the state properties
    } else {
      $this.nextUntil('.accordion-title').wrapAll('<div id="' + id + '" aria-hidden="true">');
      // $this.nextUntil('.accordion-title').wrapAll('<div id="'+ id +'" aria-hidden="true">');
      var panel = $this.next();
      // Add the button inside the <h2> so both the heading and button semanics are read
      $this.wrapInner('<button aria-expanded="false" aria-controls="' + id + '">');
      var button = $this.children('button');
      // Toggle the state properties
    }
    button.on('click', function (e) {
      e.preventDefault();
      var state = $(this).attr('aria-expanded') === 'false' ? true : false;
      $(this).attr('aria-expanded', state);
      $(this).parent('.accordion-title').toggleClass('is-active');
      panel.attr('aria-hidden', !state);
    });
  });
});